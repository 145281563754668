<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">แปลง</th>
                  <th class="text-left">หมายเหตุ</th>
                  <th class="text-left">น้ำหนัก</th>
                  <th class="text-center">รวม</th>
                  <th class="text-center">%</th>
                  <th class="text-center">ยางแห้ง</th>
                  <th class="text-right">ราคา</th>
                  <th class="text-right">รวมเงิน</th>
                  <th class="text-right">จ่าย</th>
                  <th class="text-right">ค้าง</th>
                  <th class="text-right"></th>
                  <th class="text-right"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in dsmainClose" :key="item.BillSaleId">
                  <td width="300">
                    <v-text-field disabled dense required v-model="item.RubberName" type="text"></v-text-field>
                  </td>
                  <td width="200">
                    <v-text-field disabled dense required v-model="item.BillSaleRemark" type="text"></v-text-field>
                  </td>
                  <td width="100">
                    <v-text-field disabled dense required v-model="item.RubberWeight" type="text"></v-text-field>
                  </td>
                  <td class="text-center" width="100">
                    <v-text-field
                      disabled
                      dense
                      required
                      v-model="item.RubberWeightTotal"
                      type="text"
                    ></v-text-field>
                  </td>
                  <td class="text-center" width="100">
                    <v-text-field disabled dense required v-model="item.RubberPercent" type="text"></v-text-field>
                  </td>
                  <td class="text-center" width="100">
                    <v-text-field
                      disabled
                      dense
                      required
                      v-model="item.RubberWeightDry"
                      type="text"
                    ></v-text-field>
                  </td>
                  <td class="text-center" width="100">
                    <v-text-field
                      dense
                      required
                      disabled
                      v-model="item.BillSalesPriceToday"
                      type="text"
                    ></v-text-field>
                  </td>
                  <td class="text-center" width="130">
                    <v-text-field disabled dense required v-model="item.BillSalesTotal" type="text"></v-text-field>
                  </td>
                  <td class="text-center" width="130">
                    <v-text-field
                      dense
                      disabled
                      required
                      v-model="item.BillSaleReceive"
                      type="text"
                    ></v-text-field>
                  </td>
                  <td class="text-center" width="130">
                    <v-text-field dense disabled required v-model="item.BillSaleStale" type="text"></v-text-field>
                  </td>

                  <td class="text-center" width="50">
                    <v-btn fab dark small color="lime" @click="Edit(item)">
                      <v-icon dark>fa fa-pen</v-icon>
                    </v-btn>
                  </td>
                  <td class="text-center" width="50">
                    <v-btn fab dark small color="red" @click="Delete(item)">
                      <v-icon dark>fa fa-trash-alt</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  data() {
    return {
      dsmainClose: [],
      date: {}
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.Select(this.DateState)
    });
  },
  computed: {
    ...mapState(["User", "Date"]),
    DateState() {
      console.log(this.$route.query.Date)
        return {
          Start: this.$route.query.Date,
          End: this.$route.query.Date
        };
    }
  },
  methods: {
    Select(date) {
      axios.post("/api/v1/SelectClose/BillSale", date).then(res => {
        if (res.status == 200) {
          this.dsmainClose = res.data;
        }
      });
    },
    Edit(v) {
       this.PassAccept("update", () => {
         axios
            .put("/api/v1/OnOpen/billsale?BillSaleId=" + v.BillSaleId)
            .then(res => {
              if (res.status == 200) {
                this.Select(this.DateState)
              }
            });
      });

    },
    Delete(v) {
        this.PassAccept("delete", () => {
         axios
            .delete("/api/v1/Delete/billsale?BillSaleId=" + v.BillSaleId)
            .then(res => {
              if (res.status == 200) {
                this.Select(this.DateState)
              }
            });
      });
    }
  }
};
</script>
<style scoped>
.ww {
  width: 40%;

  display: inline-block;
}
</style>
